<template>
  <div class="HealthManagement">
    <HeadView classA="1" headBg="5"></HeadView>
    <swiper :options="swiperOption" ref="mySwiper" class="swiper-no-swiping">
      <swiper-slide>
        <div class="swiperFirstWrap">
          <div class="swiperFirst">
            <p>建设强粘性的医患关系</p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="mainWhite">
      <div class="cloudClinicTop">
        <h3>健康管理平台服务</h3>
        <div class="swiperTop">
          <ul class="tabTitle">
            <li @click="tabId = 0" :class="tabId === 0 ? 'active' : ''">
              预约挂号
            </li>
            <li @click="tabId = 1" :class="tabId === 1 ? 'active' : ''">
              服药提醒
            </li>
            <li @click="tabId = 2" :class="tabId === 2 ? 'active' : ''">
              健康档案
            </li>
            <li @click="tabId = 3" :class="tabId === 3 ? 'active' : ''">
              报告解读
            </li>
          </ul>
          <div class="tabBox">
            <div class="tabCon" v-show="tabId === 0">
              <ul class="tabLeft tab0Left">
                <li>随时随地 预约挂号</li>
                <li>及时接受 就诊提醒</li>
                <li>了解诊所 诊疗特色</li>
                <li>云屏叫号 体验升级</li>
              </ul>
              <div class="tabRight tab0Right">
                <img
                  alt=""
                  src="../../assets/images/healthManagement/tab1.png"
                  width="780"
                />
              </div>
            </div>
            <div class="tabCon" v-show="tabId === 1">
              <ul class="tabLeft tab1Left">
                <li>诊后贴心 服药提醒</li>
                <li>诊所公告 精准接收</li>
                <li>高效强粘性医患互动</li>
              </ul>
              <div class="tabRight tab0Right">
                <img
                  alt=""
                  src="../../assets/images/healthManagement/tab2.png"
                  width="611"
                />
              </div>
            </div>
            <div class="tabCon" v-show="tabId === 2">
              <ul class="tabLeft tab2Left">
                <li>个人健康档案自动生成</li>
                <li>血压健康数据实时监测预警</li>
                <li>家庭健康成员一键管理</li>
                <li>日常健康小妙招</li>
              </ul>
              <div class="tabRight tab2Right">
                <img
                  alt=""
                  src="../../assets/images/healthManagement/tab3.png"
                  width="574"
                />
              </div>
            </div>
            <div class="tabCon" v-show="tabId === 3">
              <ul class="tabLeft tab3Left">
                <li>病历报告 便捷上传</li>
                <li>医生线上 高效解读</li>
                <li>疑难杂症 不出远门</li>
                <li>轻松预约 权威专家</li>
              </ul>
              <div class="tabRight tab3Right">
                <img
                  alt=""
                  src="../../assets/images/healthManagement/tab4.png"
                  width="564"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cloudClinicMiddle">
      <div class="cloudClinicMiddleMain">
        <h3>致医智能医疗设备</h3>
        <swiper :options="swiperOption1" ref="mySwiper1">
          <!-- slides -->
          <swiper-slide>
            <div class="hardwareWrap">
              <a
                target="_blank"
                href="https://detail.youzan.com/show/goods?alias=2xmnqjy6mogux&activity_alias=undefined"
              >
                <h6>致医智能血压计D1</h6>
                <div class="hardwareImg">
                  <img
                    alt=""
                    src="../../assets/images/healthManagement/hardwareD1.png"
                  />
                </div>
                <div class="knowBtn">了解详情</div>
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="hardwareWrap">
              <a
                target="_blank"
                href="https://detail.youzan.com/show/goods?alias=36cqzr0cy9cjd&activity_alias=undefined"
              >
                <h6>致医智能血压计D2</h6>
                <div class="hardwareImg">
                  <img
                    alt=""
                    src="../../assets/images/healthManagement/hardwareD2.png"
                  />
                </div>
                <div class="knowBtn">了解详情</div>
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="hardwareWrap">
              <a
                target="_blank"
                href="https://detail.youzan.com/show/goods?alias=1yge0zhej4k09&activity_alias=undefined"
              >
                <h6>致医远程问诊终端</h6>
                <div class="hardwareImg">
                  <img
                    alt=""
                    src="../../assets/images/healthManagement/hardwareC1.png"
                  />
                </div>
                <div class="knowBtn">了解详情</div>
              </a>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="hardwareWrap">
              <h6>致医智能血压计D3</h6>
              <div class="hardwareImg">
                <img
                  alt=""
                  src="../../assets/images/healthManagement/hardwareD3.png"
                />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="hardwareWrap">
              <h6>致医智能血压计G1</h6>
              <div class="hardwareImg">
                <img
                  alt=""
                  src="../../assets/images/healthManagement/hardwareG1.png"
                />
              </div>
            </div>
          </swiper-slide>
          <!-- Optional controls -->
          <!-- 如果需要导航按钮 -->
          <div
            class="swiper-button-prev swiper-button-prev1"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next1"
            slot="button-next"
          ></div>
        </swiper>
      </div>
    </div>

    <div class="bottomWrap">
      <p class="title">官方代理火热招募中，期待您的加盟！</p>
      <p class="paragraph">
        助力基层医疗行业发展，提升百万基层医疗机构竞争力，服务数亿基层患者
      </p>
      <span @click="showJoin1" class="joinBtn">立即加盟</span>
    </div>
    <Foot></Foot>
    <JoinBox
      ref="joinBox1"
      companyText="企业名称（选填）"
      type="0"
      title="申请加盟官方代理"
    ></JoinBox>
  </div>
</template>
<style scoped>
@import "../../assets/style/hardware.css";
</style>
<style lang="scss" scoped>
.HealthManagement {
  .swiperFirstWrap {
    width: 100%;
    height: 550px;
    background: url("../../assets/images/healthManagement/banner1Bg.png")
      no-repeat;
    background-size: cover;
    .swiperFirst {
      width: 79.17%;
      max-width: 1140px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 60px;
        color: #fff;
      }
    }
  }

  .mainWhite {
    width: 100%;
    background: #fff;
    .cloudClinicTop {
      width: 79.17%;
      max-width: 1140px;
      margin: 0 auto;
      h3 {
        padding: 60px 0;
        font-size: 28px;
        color: #1a1c1f;
        font-weight: bold;
      }
      .tabTitle {
        display: flex;
        justify-content: space-between;
        li {
          border: 1px solid #ccc;
          height: 30px;
          line-height: 30px;
          width: 25%;
          text-align: center;
          color: #1a1c1f;
          font-size: 24px;
          padding: 11px 0;
          cursor: pointer;
        }
        li.active {
          border: 1px solid #6236ff;
          color: #6236ff;
        }
      }
      .tabBox {
        padding: 80px 0;
        .tabCon {
          display: flex;
          justify-content: space-around;
          align-items: center;
          .tabLeft {
            li {
              color: #1a1c1f;
              font-size: 20px;
              font-weight: 400;
              line-height: 48px;
              width: 256px;
              height: 48px;
              background: rgba(255, 255, 255, 1);
              box-shadow: 0px 0px 10px 0px rgba(232, 232, 232, 0.5);
              border-radius: 4px;
              margin-bottom: 10px;
            }
          }
          .tabRight {
            height: 432px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .bottomWrap {
    width: 100%;
    height: 300px;
    background: linear-gradient(
      310deg,
      rgba(49, 10, 186, 1) 0%,
      rgba(98, 54, 255, 1) 100%
    );
    text-align: center;
    .title {
      font-size: 32px;
      color: #fff;
      line-height: 45px;
      padding: 63px 0 22px 0;
    }
    .paragraph {
      font-size: 20px;
      color: #fff;
      line-height: 28px;
    }
    .joinBtn {
      display: inline-block;
      width: 160px;
      height: 44px;
      line-height: 44px;
      font-size: 14px;
      color: #6236ff;
      cursor: pointer;
      margin-top: 43px;
      border-radius: 4px;
      background: #fff;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 1280px) {
    .main,
    .mainWhite .cloudClinicTop {
      width: 90%;
    }
  }
  @media screen and (max-width: 1024px) {
    .swiperFirstWrap {
      height: 320px;
      .swiperFirst {
        .textWrap {
          p {
            font-size: 32px;
          }
        }
      }
    }
    .main,
    .mainWhite .cloudClinicTop {
      width: 94%;
    }
    .mainWhite .cloudClinicTop .tabBox .tabCon .tabRight {
      width: 50%;
    }
    .mainWhite .cloudClinicTop .tabBox .tabCon .tabRight img {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    .swiperFirstWrap {
      height: 260px;
      .swiperFirst {
        width: 94%;
        p {
          font-size: 40px;
        }
      }
    }
    .mainWhite {
      .cloudClinicTop {
        .tabBox {
          padding: 0;
          .tabCon {
            .tabLeft {
              width: 50%;
              padding-right: 30px;
              box-sizing: border-box;
              li {
                width: 100%;
                margin: 0 auto;
              }
            }
            .tabRight {
              width: 50%;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .bottomWrap {
      .title {
        font-size: 30px;
        line-height: 30px;
        padding: 43px 0 25px 0;
      }
    }
  }
}
</style>
<script>
// @ is an alias to /src
import Foot from "@/components/common/Foot";
import HeadView from "@/components/common/Head";
import JoinBox from "@/components/common/JoinBox";

export default {
  name: "home",
  components: {
    HeadView,
    Foot,
    JoinBox,
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 允许点击小圆点跳转
        },
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false // 手动切换之后继续自动轮播
        // },
        autoplay: false,
        loop: true,
      },
      tabId: 0,
      swiperOption1: {
        navigation: {
          nextEl: ".swiper-button-next1",
          prevEl: ".swiper-button-prev1",
        },
        autoplay: false,
        loop: true,
        spaceBetween: 0,
        slidesPerView: 3, // 开启自定义slide宽度，配合下面css样式设置即可
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination1",
          clickable: true,
        },
      },
    };
  },
  methods: {
    tab(index) {
      this.num = index;
    },
    showJoin1() {
      this.$refs.joinBox1.isShowDialog = true;
    },
  },
  computed: {},
  mounted() {},
};
</script>
